<template>
    <div class="nav-wrapper">
        <nav id="mobile-navbar" class="navbar">
            <ul>

                <li class="nav-logo">
                    <router-link :to="{name: 'Home'}"> 
                        <img src="@/assets/Logo.svg" class="no-drag"/>
                    </router-link>
                </li>

                <div class="mobile-title no-drag">
                    Fémina Vicques
                </div>

                <div>
                    <button @click="showNav()" id="nav-switcher">
                        <img src="@/assets/menu.svg"/>
                    </button>
                </div>

                <div id="mobile-toggle-nav" ref="mobileNavbar" class="nav-hidden" @click="hideNav()">
                    <div>
                        <ul>
                            <li v-for="nav in navigations" :key="nav.text">
                                <router-link :to="nav.clickable == false ? {} : {name: nav.target}"> 
                                    {{ nav.text }}
                                    <span v-if="nav.submenus">
                                        <ul>
                                            <li v-for="submenu in nav.submenus" :key="submenu.text">
                                                <router-link :to="{ name: submenu.target, params: submenu.parameters }">
                                                    {{ submenu.text }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

            </ul>
        </nav>

        <!--
                DESKTOP NAVIGATION
        -->
        <nav id="desktop-navbar" class="navbar" ref="desktopNavbar">
            <ul>
                <li class="nav-logo">
                    <router-link :to="{name: 'Home'}"> 
                        <img src="@/assets/Logo.svg" class="no-drag"/>
                    </router-link>
                    <div class="nav-logo-title">
                        <div class="no-drag">Fémina</div>
                        <div class="no-drag">Vicques</div>
                    </div>
                </li>

                <li>
                    <ul>
                        <li v-for="nav in navigations" :key="nav.text">
                            <router-link 
                                    :to="nav.clickable == false ? {} : {name: nav.target}" 
                                    :class="'nav-item ' + (nav.submenus ? 'nav-submenus ' : '') + (nav.clickable == false ? '' : 'clickable ')"> 
                                {{ nav.text }}
                                <span v-if="nav.submenus">
                                    <ul>
                                        <li v-for="submenu in nav.submenus" :key="submenu.text" :style="submenu.size ? ('font-size:' + submenu.size) : ''">
                                            <router-link :to="{name: submenu.target, params: submenu.parameters}">
                                                {{ submenu.text }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </span>
                            </router-link>
                        </li>
                        <li class="social-network">
                            <a href="https://www.facebook.com/people/F%C3%A9mina-Vicques-Gymnastique-Danse/100029087366561/"
                               title="Vers notre page Facebook">
                                <img src="@/assets/sn-icon-filled1.svg"/>
                            </a>
                        </li>
                        <li class="social-network">
                            <a href="https://www.instagram.com/feminavicques/" 
                               title="Vers notre Instagram">
                                <img src="@/assets/sn-icon-filled2.svg"/>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>

const DISMISS_MOBILE_NAV_KEYS = 
[
    'Escape',
    'Backspace',
    'Enter'
];

const COLLAPSE_NAV_DELTA_Y = 100;

export default {
    name: 'Navigation',

    props: [
        'navigations'
    ],

    methods: {
        showNav: function () {
            this.$refs.mobileNavbar.style.display = "block";
            setTimeout(() => { this.$refs.mobileNavbar.className = '' }, 0)
        },

        hideNav: function() {
            this.$refs.mobileNavbar.className = 'nav-hidden';   
            setTimeout(() => { this.$refs.mobileNavbar.style.display = "none" }, 300)
        },

        keyPressed: function(event) {
            if (DISMISS_MOBILE_NAV_KEYS.includes(event.code)) {
                this.hideNav();
            }
        }
    },

    mounted: function() {
        window.onscroll = () => { 
            if (window.scrollY >= COLLAPSE_NAV_DELTA_Y) {
                this.$refs.desktopNavbar.classList.add('scrolled');
            }
            else {
                this.$refs.desktopNavbar.classList.remove('scrolled');
            }
        };

        window.addEventListener('keyup', this.keyPressed)
    }
}
</script>

<style lang="scss" scoped>

.social-network > a > img {
    width: 30px;
}

.nav-logo-title {
    & > div {
        font-size: 2rem;
        text-transform: uppercase;
    }
    & > div:first-child {
        font-size: 2.4rem;
    }
}

#nav-switcher {
    background: none;
    border: none;

    & > img {
        width: 44px;
    }
}

.nav-submenus {
    text-align: center;
    width: 250px;

    &:not(.clickable) {
        cursor: default;
    }
}

#mobile-toggle-nav {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    opacity: 1;

    transition: opacity 0.3s ease-in-out;

    display: none;

    &.nav-hidden {
        opacity: 0;

        & > div {
            right: -50%;
        }
    }

    & > div {
        background: white;

        border-left: 1px solid #888;
        position: absolute;
        
        display: block;
        height: 100%;
        top: 0;
        right: 0;
        
        padding: 20px 20px;

        transition: right 0.3s ease-in-out;

        & > ul {
            margin: 0;
            padding: 0;
            & > li {
                list-style: none;

                & > a {
                    text-decoration: none;
                    display: block;
                    padding: 15px 10px;
                    color: #333;
                    font-size: 1.1rem;
                    text-transform: uppercase;

                    & span {
                        & > ul {
                            & > li {
                                list-style: none;

                                & > a {
                                    display: block;
                                    padding: 15px 10px 5px;
                                    color: #333;
                                    text-decoration: none;
                                    font-size: 1.05rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-title {
    font-size: 1.2rem;
    text-transform: uppercase;
}

.nav-wrapper {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: white;
    border-bottom: 1px solid #f0f0f0;
}

#desktop-navbar > ul,
#mobile-navbar > ul {
    justify-content: space-between;
}

#desktop-navbar > ul {
    margin: 0.6rem 5rem;
}

#desktop-navbar > ul > li > ul {
    width: 100%;
    justify-content: space-between;
    display: flex;
}

#desktop-navbar > ul > li > ul,
.navbar > ul {

    padding: 0;

    display: flex;
    justify-content: space-around;
    align-items: center;

    list-style: none;

    margin: 0 20px;


    & > li:not(.nav-logo) {

        & > a {
            border-radius: 5px;
            padding: 15px;
            display: inline-block;
            text-transform: uppercase;
            background-color: white;
            color: #222;

            font-size: 0.9rem;
            text-decoration: none;
            color: #222;

            transition: 0.3s ease-in-out;
            position: relative;

            & > span {
                z-index: 99;

                background: white;
                position: absolute;

                border-radius: 5px;

                top: 100%;
                width: 100%;
                left: 0;

                height: 0;
                max-height: 0;

                overflow: hidden;

                transition: max-height 0.3s ease-in-out;

                & > ul {
                    display: block;
                    padding: 0;

                    & > li {
                        text-align: center;
                        display: block;
                        padding: 0;

                        & > a {
                            display: block;
                            padding: 15px 25px;
                            text-decoration: none;
                            color: #222;

                            transition: 0.25s ease-in-out;

                            &:hover {
                                background: #f0f0f0;
                            }
                        }
                    }
                }
            }

            &:hover > span {
                height: auto;
                border: 1px solid #f0f0f0;
                max-height: 350%;
                filter: drop-shadow(0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.2));
            }
        }

        &:hover {
            
            & > a {
                background-color: #eee;
                color: black;
            }
        }
    }
}

#mobile-navbar .nav-logo > a > img {
    max-width: 3rem;
    margin-right: 0rem;
}

#desktop-navbar.scrolled .nav-logo > a > img {
    max-width: 80px;
}

.nav-logo {
    justify-content: space-around;
    display: flex;
    align-items: center;

    & > a > img {
        margin-right: 5%;
        transition: max-width 0.3s ease-in-out;
        max-width: 150px;
    }
}

#mobile-navbar {
    display: none;
}

#desktop-navbar {
    display: inherit;
}


@media (max-width: 920px) {
    #mobile-navbar {
        display: inherit;
    }
    #desktop-navbar {
        display: none;
    }
}


@media (min-width: 1440px) {
    #desktop-navbar > ul {
        margin: 1% 3%;
    }
    
    .nav-submenus {
        width: 200px;
    }

    #desktop-navbar > ul > li > ul > li:not(.nav-logo) > a {
        font-size: 20px;
    }

    
    .nav-logo-title {
        & > div {
            font-size: 32px;
            text-transform: uppercase;
        }
        & > div:first-child {
            font-size: 38px;
        }
    }
    
    .nav-logo {
        & > a > img {
            margin-right: 50px;
        }
    }
}

@media (max-width: 1500px) {
    #desktop-navbar > ul {
        margin: 1% 2%;
    }
    .nav-logo {
        & > a > img {
            margin-right: 50px;
            max-width: 140px;
        }
    }

    .nav-submenus {
        width: 200px;
    }
    
    .nav-logo-title {
        & > div {
            font-size: 30px;
            text-transform: uppercase;
        }
        & > div:first-child {
            font-size: 34px;
        }
    }

    
    #desktop-navbar > ul > li > ul > li:not(.nav-logo) > a {
        font-size: 20px;
    }
}

@media (max-width: 1450px) { 
    
    .nav-logo {
        & > a > img {
            margin-right: 20px;
            max-width: 100px;
        }
    }
    
    .nav-submenus {
        width: 180px;
    }

    .nav-logo-title {
        & > div {
            font-size: 23px;
            text-transform: uppercase;
        }
        & > div:first-child {
            font-size: 28px;
        }
    }
   
    #desktop-navbar > ul > li > ul > li:not(.nav-logo) > a {
        font-size: 18px;
    }
}   

@media (max-width: 1270px) {
    .nav-logo {
        & > a > img {
            margin-right: 20px;
            max-width: 80px;
        }
    }
    
    .nav-submenus {
        width: 160px;
    }

    .nav-logo-title {
        & > div {
            font-size: 20px;
            text-transform: uppercase;
        }
        & > div:first-child {
            font-size: 24px;
        }
    }
   
    #desktop-navbar > ul > li > ul > li:not(.nav-logo) > a {
        font-size: 16px;
    }
}


@media (max-width: 1150px) {
    .nav-logo {
        & > a > img {
            margin-right: 20px;
            max-width: 80px;
        }
    }
   
    #desktop-navbar > ul > li > ul > li:not(.nav-logo) > a {
        font-size: 17px;
    }
    
    .nav-logo-title {
        display: none;
    }
}

@media (max-width: 1065px) {
    
    .nav-submenus {
        width: 160px;
    }
    
    #desktop-navbar.scrolled .nav-logo > a > img {
        max-width: 50px;
    }

    .nav-logo {
        & > a > img {
            margin-right: 10px;
            max-width: 70px;
        }
    }
    #desktop-navbar > ul > li > ul > li:not(.nav-logo) > a{
        font-size: 15px;   
    }
}


@media (max-width: 870px) {
}

@media (max-width: 980px) {
    #desktop-navbar > ul > li > ul > li:not(.nav-logo) > a{
        font-size: 12.8px;
    }
}

</style>
