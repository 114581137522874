<template>
    <div class="container">
        <div>
            <div class="header">
                <h3>
                    {{ title }}
                </h3>

                <span>
                    <div>
                        <div v-if="age !== undefined">
                            {{ age }}
                        </div>

                        <div>
                            {{ day }} - {{ hour }}
                        </div>
                    </div>

                <div v-if="contact" class="contact">
                    <div class="contact-overlay">
                        <div class="arrow"></div>
                        <div>
                            {{ contact.name }}
                        </div>
                        <div>
                            <a class="phone-number" :href="'tel:' + contact.phone">{{ contact.phone }}</a>
                        </div>
                    </div>
                    <img :src="require('@/assets/telephone.png')" alt="telephone">
                </div>
                
                </span>
            </div>

            <div class="text">
                {{ text }}
            </div>

            <div>
                <img :src="img_base_url + image" :alt="'image de ' + title"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'title',
        'day',
        'image',
        'hour',
        'text',
        'age',
        'contact'
    ],

    data: () => {
        return {
            img_base_url: 'https://femina-vicques.ch/'
        }
    }
}
</script>

<style lang="scss" scoped>

@import "@/scss/colors.scss";

a.phone-number {
    color: black;
    text-decoration: none;
}

.container {
    padding: 40px;
    padding-top: 10px;
    margin: 2rem;

    max-width: 1100px;

    border: 1px solid #efefef;
    background: #fdfdfd;

    & img {
        max-width: 100%;
        border-radius: 14px;
        margin-top: 2rem;
    }

    & .header {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & > span {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }
        
        & .contact {
            position: relative;
            margin-left: 5px;

            & > img {
                border-radius: 0;
                max-width: 32px;
                margin-top: 0;
                margin-left: 20px;

                transition: opacity 0.25s ease-in-out;
            }

            & > .contact-overlay {
                font-size: 16px;
                text-align: center;
                width: 250px;
                height: 70px;
                left: -175px;
                top: -85px;

                background: white;
                border-radius: 14px;
                border: 1px solid #e3e3e3;
                position: absolute;
                opacity: 0;
                z-index: 2;
                
                -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.08);
                -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.08);
                box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.08);

                transition: opacity 0.25s ease-in-out;

                & > .arrow {    
	                position: absolute;
	                width: 0;
	                height: 0;
	                border-style: solid;
	                border-width: 0 10px 10px 10px;
	                border-color: transparent transparent #ddd transparent;
                    transform: rotate(180deg);
	                left:187px;
	                bottom:-10px;
	                z-index:2;
                }

                & > .arrow::after {
	                content:"";
	                position: absolute;
	                width: 0;
	                height: 0;
	                border-style: solid;
	                border-width: 0 10px 10px 10px;
	                border-color: transparent  transparent white transparent;
	                left:-10px;
	                top:1px;
	                z-index:1;
                }

                & > div:not(:first-child)
                {
                    margin-top: 5px;
                }                
            }
        }
        
        & .contact:hover {
            &  > img {
                opacity: 0.7;
            }
            & > .contact-overlay {
                opacity: 1;
            }
        }

        & > h3 {
            display: inline-block;
            text-transform: uppercase;
            font-weight: 200;
            color: $femina-color-blue;
            font-size: 30px;
        }

        & > span > div { 
            margin-top: 30px;
            font-size: 15px;

            & > div {
                margin-left: 15px;
                border-radius: 14px;
                display: inline-block;
                border: 1px solid $femina-color-green;
                color: $femina-color-green;
                padding: 8px 17px;
                align-self: center;
            }

        }
    }

    & .text {
        font-size: 1.3rem;
        text-align: justify;
    }
}


@media (max-width: 1100px) {
    .container {
        & .header {

            & > h3 {
                font-size: 1.7rem;
            }

            & > div { 
                margin-top: 25px;
                & > div {
                    padding: 9px 17px;
                }
            }
        }
        
        & .text {
            font-size: 1.2rem;
        }
    }
}

@media (max-width: 900px) {
    .container {
        & .header {

            & > h3 {

            }

            & > div { 
                & > div {

                }
            }
        }

        
        & .text {
            font-size: 1.1rem;
        }
    }
}

@media (max-width: 750px) {
    .container {
        margin: 1rem 0;
        & .header {

            & > h3 {

            }

            & > div { 
                & > div {

                }
            }
        }

        
        & .text {
            font-size: 1.1rem;
        }
    }
}

@media (max-width: 600px) {
    .container {
        & .header {
            & > h3 {
                font-size: 1.2rem;
            }

            & > div {
                margin-top: 13px;
            }

            & > div > div {
                font-size: 0.8rem;
                margin: right 2px;;
                padding: 5px 10px;
            }
        }
    }
}

</style>

