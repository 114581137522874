<template>
    <div class="root">
        <template v-for="offer in sportiveOffers" :key="offer.title">
            <SportiveOffer
                :title="offer.title"
                :day="offer.day"
                :hour="offer.hour"
                :text="offer.text"
                :image="offer.image"
                :age="offer.age"
                :contact="offer.contact"
            ></SportiveOffer>
        </template>
    </div>
</template>

<script>
import SportiveOffer from '@/components/SportiveOffer.vue'

export default {
    components:  { SportiveOffer },

    data: () => {
        return {
            sportiveOffers: [
                {
                    title: 'Athlétisme kids petits',
                    age: 'De 7 à 9 ans',
                    day: 'Jeudi',
                    hour: '17h15 à 18h30',
                    image: '/images/Athletisme_petits_kids_site.jpg',
                    text: 'Les enfants de 7 à 9 ans découvrent l’athlétisme par des formes jouées. Coordination, vitesse, agrès et jeux orientés permettent de développer leur motricité en exerçant une large palette de mouvements. Différentes disciplines sont initiées, comme la course, le saut et le lancer. Le groupe participe à quelques concours en équipe.',
                    contact: 
                    {
                        name: 'Marie-Claude Fähndrich',
                        phone: '079 469 57 11'
                    }
                },
                
                {
                    title: 'Athlétisme kids grands',
                    age: 'De 10 à 12 ans',
                    day: 'Jeudi',
                    hour: '18h30 à 19h45',
                    image: '/images/Athletisme_grands_kids_site.jpg',
                    text: 'Les athlètes de 10 à 12 ans améliorent leur vitesse, leur condition physique générale et leur technique dans les disciplines fondamentales que sont la course, le saut et le lancer. Le dynamisme et l’esprit de compétition sont stimulés. Les intéressés participent à quelques concours individuels.',
                    contact: 
                    {
                        name: 'Marie-Claude Fähndrich',
                        phone: '079 469 57 11'
                    }
                },
                
                {
                    title: 'Athlétisme ados/adultes',
                    day: 'Lundi et mercredi',
                    hour: '18h30 à 20h',
                    image: '/images/Athletisme_ados_site.jpg',
                    text: 'Dans ce groupe, le programme d’entraînement vise à développer la vitesse, la condition physique spécifique et le perfectionnement de la technique. Les athlètes participent à des compétitions régionales et nationales.',
                    contact: 
                    {
                        name: 'Marie-Claude Fähndrich',
                        phone: '079 469 57 11'
                    }
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>

.root {
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

</style>
