<template>
    <div class="container">
        <h2>Choisir une offre</h2>
        <div class="offers-wrapper">

            <div v-for="offer in offers" :key="offer.name" class="offer-type">
                <h3>
                    {{ offer.name }}
                </h3>
                <router-link :to="{ name: offer.target }">
                    Vers les offres
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            offers: [
                {
                    name: 'Athlétisme',
                    target: 'Athletism'
                },
                {
                    name: 'Gym-Danse',
                    target: 'Gym-Dance'
                },
                {
                    name: 'Gymnastique',
                    target: 'Gymnastics'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>


@import "@/scss/colors.scss";

.container {
    margin: 18rem  20%;
}

h2 {
    color: $femina-color-blue;
    font-weight: 100;
    font-size: 2.5rem;
    text-transform: uppercase;
}

.offers-wrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.offer-type {
    text-align: center;
    border: 1px solid #fefefe;
    display: inline-block;
    text-transform: uppercase;

    padding: 40px;

    border: 1px solid #ddd;
    background: #f8f8f8;

    margin-bottom: 20px;

    & > h3 {
        font-weight: 200;
        margin-bottom: 2rem;
        color: $femina-color-blue;
    }
    
    & > a {
        display: block;
        text-decoration: none;
        padding: 25px 45px;
        border: 2px solid $femina-color-green;
        background-color: $femina-color-green;
        color: #f8f8f8;
        font-size: 1.2rem;
        transition: 0.3s ease-in-out;
        text-transform: uppercase;
        border-radius: 7px;

        max-width: 300px;

        &:hover {
            cursor: pointer;
            background: #f8f8f8;
            color: $femina-color-green;
        }
    }
}

@media (max-width: 1870px) {
    .container {
        margin: 18rem  15%;
    }
}

@media (max-width: 1700px) {
    .container {
        margin: 18rem  10%;
    }
}

@media (max-width: 1400px) {
    .container {
        margin: 18rem  7%;
    }
}

@media (max-width: 1300px) {
    .container {
        margin: 17rem  5%;
    }

    .offer-type {
        & > a {
            padding: 20px 35px;
        }
    }
}

@media (max-width: 1175px) {
    .container {
        margin: 15rem  5%;
    }

    .offer-type {
        padding: 20px;
        & > a {
            padding: 20px 35px;
        }
    }
}

@media (max-width: 1000px) {
    .container {
        margin: 12rem  4%;
    }

    .offer-type {
        padding: 20px;
        & > a {
            padding: 15px 25px;
            font-size: 1rem;
        }
    }
}

@media (max-width: 1000px) {

    .offers-wrapper {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .offer-type {
        & > a {
            width: 100%;
            max-width: 100%;
        }
    }
}

@media (max-width: 800px) {
    .container {
        margin: 7rem  4%;
    }
}


</style>
