<template>
    <div class="container">
        <h2>Formulaire de demande d'adhésion</h2>
        <p>
            Une fois la possibilité d'inscription validée par la monitrice, veuillez remplir le formulaire d'adhésion 
            en cliquant sur le bouton de téléchargement ci-dessous.
        </p>

        <div class="button-container">
            <a href="files/Demande_d_adhesion_Femina.pdf" download="Demande d'adhésion">
                Télécharger la demande d'adhésion
            </a>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

@import "@/scss/colors.scss";

.container {

    max-width: 800px;
    margin: auto;
    margin-top: 18rem;

    & > h2 {
        color: $femina-color-blue;
        font-weight: 100;
        font-size: 2.5rem;
        text-transform: uppercase;
    }

    & > p {
        margin-bottom: 45px;
        margin-left: 10px;
    }

    & .button-container {
        text-align: center;
        & > a {
            text-decoration: none;
            padding: 15px 25px;
            border: 2px solid $femina-color-green;
            background-color: $femina-color-green;
            color: #f8f8f8;
            font-size: 1.2rem;
            transition: 0.3s ease-in-out;
            line-height: 1;
            text-transform: uppercase;
            border-radius: 7px;
            &:hover {
                cursor: pointer;
                background: #f8f8f8;
                color: $femina-color-green;
            }
        }
    }
}

@media (max-width: 950px) {
    .container {
        margin-top: 10rem;
        max-width: 90%;

        & > .button-container {
            & > a {
                font-size: 1rem;
            }
        }
    }
}

@media (max-width: 450px) {
    .container {
        max-width: 90%;

        & > .button-container {
            & > a {
                font-size: 0.8rem;
            }
        }
    }
}

</style>
