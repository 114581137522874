<template>
    <section>
        <div>
            <h2>Costumes</h2>
            <p>
                La société Fémina Vicques propose la location de ses costumes.
            </p>

            <p>
                Pour en connaître les conditions, utilisez le fomulaire de contact de notre site et nous vous répondrons
                dans les meilleurs délais.
            </p>
        </div>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image1.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image2.jpeg">
            </div>
        </div>
        <p class="image-caption">5x taille 6-7ans</p>


        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image3.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image4.jpeg">
            </div>
        </div>
        <p class="image-caption">
            1x taille 120 <br>
            1x taille 130 <br>
            1x taille 140 <br>
            1x taille 150 <br>
            1x taille 160 <br>

        </p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image5.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image6.jpeg">
            </div>
        </div>
        <p class="image-caption">
            6x taille 12-13 ans <br>
            4x taille 14-15 ans <br>
            1x taille 16 ans <br>
        </p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image7.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image8.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 9-10 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image9.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image10.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 7-8 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image11.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image12.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 38</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image13.jpeg">
            </div>

        </div>
        <p class="image-caption">
            2x taille 7-8 ans <br>
            2x taille 9-10 ans <br></p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image14.jpeg">
            </div>

        </div>
        <p class="image-caption">
            6x taille 7-8 ans <br>
            3x taille 9-11 ans <br></p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image15.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image16.jpeg">
            </div>
        </div>
        <p class="image-caption">
            2x taille 12 ans <br>
            2x taille 14 ans <br></p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image17.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image18.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 14-16 ans (S)</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image20.jpeg">
            </div>

        </div>
        <p class="image-caption">
            1x taille 5-6 ans <br>
            6x taille 7-8 ans <br>
            5x taille 9-10 ans <br>
            1x taille 11-12 ans <br>
            4x taille 13-14 ans <br>
        </p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image21.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image22.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 14-16 ans (S)</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image23.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image24.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille M</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image25.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image26.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 12-14 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image27.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image28.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille S</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image29.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image30.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille XS (il taille grand)</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image31.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image32.jpeg">
            </div>
        </div>
        <p class="image-caption">Top rouge <br> 2x taille 10 ans <br><br>
            Jupe noire<br> 2x taille 34 <br> 2x taille 36</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image33.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image34.jpeg">
            </div>
        </div>
        <p class="image-caption">Short rouge <br> 1x taille 14 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image35.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image36.jpeg">
            </div>
        </div>
        <p class="image-caption">Body noir et tutu rose <br> 1x taille 8-9 ans</p>




        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image37.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image38.jpeg">
            </div>
        </div>
        <p class="image-caption">Short rose <br> 1x taille 12 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image39.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image40.jpeg">
            </div>
        </div>
        <p class="image-caption">Tutu violet <br><br> 3x taille 9-10 ans <br>
            2x taille 11-12 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image41.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image42.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille L</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image43.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image44.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille XS</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image45.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image46.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 12 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image47.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image48.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 12 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image49.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image50.jpeg">
            </div>
        </div>
        <p class="image-caption">Body rouge <br> 1x taille 12-14 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image51.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image52.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 10-12 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image53.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image54.jpeg">
            </div>
        </div>
        <p class="image-caption">Top argenté <br> 3x taille S/M</p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image55.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image56.jpeg">
            </div>
        </div>
        <p class="image-caption">Top doré <br> 2x taille 14-16 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image57.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image58.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 10-12 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image59.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image60.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille L <br> 1x taille XL</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image61.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image62.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 10 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image63.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image64.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille S <br> 2x taille M<br>1x taille XL <br></p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image65.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image66.jpeg">
            </div>
        </div>
        <p class="image-caption">3x taille S/M</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image67.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image68.jpeg">
            </div>
        </div>
        <p class="image-caption">3x taille S/M</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image69.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image70.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 10 ans <br> 1x taille 12 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image71.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image72.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 12 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image73.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image74.jpeg">
            </div>
        </div>
        <p class="image-caption">3x taille L <br> 1x taille XL</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image75.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image76.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 14-16 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image77.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image78.jpeg">
            </div>
        </div>
        <p class="image-caption">Short doré <br> 1x taille L</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image79.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image80.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille M <br> 1x taille L</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image81.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image82.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 12 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image83.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image84.jpeg">
            </div>
        </div>
        <p class="image-caption">Top turquoise <br> 1x taille 12 ans <br> <br> Pantalon noir <br> 5x taille S/M</p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image85.jpeg">
            </div>
        </div>
        <p class="image-caption">Jupe et top <br><br> 3x taille 7-8 ans <br>
            5x taille 9-10 ans<br>
            5x taille 11-12 ans<br>
            2x taille 13-14 ans<br> </p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image86.jpeg">
            </div>
        </div>
        <p class="image-caption">Top noir et doré <br>2x taille 10-12 ans <br><br>Short "Carla Sport" <br>1x taille S </p>
        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image87.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image88.jpeg">
            </div>
        </div>
        <p class="image-caption">Jupe noire<br>1x taille 12-14 ans
        </p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image89.jpeg">
            </div>
            <div class="image-wrapper">
                <img src="/images/Costumes/image90.jpeg">
            </div>
        </div>
        <p class="image-caption">Jupe noire<br>1x taille 11-12 ans</p>



        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image91.jpeg">
            </div>
        </div>
        <p class="image-caption">Short noir "Gym"<br>1x taille 12 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image92.jpeg">
            </div>
        </div>
        <p class="image-caption">Short noir "FSG"<br>1x taille 12 ans</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image93.jpeg">
            </div>
        </div>
        <p class="image-caption">1x taille 5-6 ans <br>
            2x taille 7-8 ans<br>
            5x taille 9-10 ans<br>
            5x taille 11-12 ans<br> </p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image94.jpeg">
            </div>
        </div>
        <p class="image-caption">17 robes rouges <br>
            <br>1x taille 5-6 ans
            <br>6x taille 7-8 ans
            <br>5x taille 9-10 ans
            <br>1x taille 11-12 ans
            <br>4x taille 13-14 ans
        </p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image95.jpeg">
            </div>
        </div>
        <p class="image-caption">Short beige <br>1x taille XS</p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image96.jpeg">
            </div>
        </div>
        <p class="image-caption">Short beige <br>1x taille XS</p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image97.jpeg">
            </div>
        </div>
        <p class="image-caption">Short beige <br>1x taille L</p>


        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image98.jpeg">
            </div>
        </div>
        <p class="image-caption">Short blanc<br><br>1x taille S <br>1x taille L <br>1x taille XXL</p>

        <!-- Ajouter les images et la légende ici -->
        <div class="image-container">
            <div class="image-wrapper">
                <img src="/images/Costumes/image99.jpeg">
            </div>
        </div>
        <p class="image-caption">Jupes rouges <br> 3x taille 12 ans</p>




    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";

section {
    padding: 2.5rem 30%;
    margin: 5rem 0;

    &>div {
        font-size: 1.2rem;
        max-width: 800px;

        text-align: left;

        &>h2 {
            color: $femina-color-blue;
            font-weight: 100;
            font-size: 2.5rem;
            text-transform: uppercase;
        }

        &>p {
            text-align: justify;
        }
    }

    width: 100%;

    background: #fafafafa;
    border: 1px solid #f7f7f7;
}

@media (max-width: 1500px) {

    section {
        padding: 2.5rem 20%;
    }

}

@media (max-width: 1000px) {

    section {
        padding: 2.5rem 10%;
    }

}

.image-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.image-wrapper {
    float: left;
    width: 380px;
    margin-right: 2%;
    /* Ajustez le pourcentage selon vos besoins */


    img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        display: block;
        margin: 0 auto;
        margin-left: auto;
    }

    &:last-child {
        margin-right: 0;
        /* Supprime la marge à droite pour la dernière image */

    }
}


.image-caption {
    text-align: left;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 80px;
    color: #000000;
    /* Couleur du texte de la légende */

}</style>