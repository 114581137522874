<template>
    <div class="root">
        <template v-for="offer in sportiveOffers" :key="offer.title">
            <SportiveOffer :title="offer.title" :day="offer.day" :hour="offer.hour" :text="offer.text"
                :image="offer.image" :contact="offer.contact"></SportiveOffer>
        </template>
    </div>
</template>

<script>
import SportiveOffer from '@/components/SportiveOffer.vue'

export default {
    components: { SportiveOffer },

    data: () => {
        return {
            sportiveOffers: [
                {
                    title: 'Parent-enfant',
                    day: 'Lundi',
                    hour: '17h00 à 18h00',
                    image: '/images/Gym parent-enfant site.jpg',
                    text: 'Les gymnastes ont entre 2 ans et demi et 4 ans. Lors de la leçon, ils bougent, sautent et passent un bon moment avec maman ou papa. Toutes les activités de développement de la motricité se déroulent dans la joie et bonne humeur.',
                    contact:
                    {
                        name: 'Magali Caillet',
                        phone: '032 435 60 43'
                    }
                },

                {
                    title: 'Gymnastique 1-2H',
                    day: 'Mercredi',
                    hour: '16h15 à 17h05',
                    image: '/images/Gym1-2H.jpg',
                    text: 'Les leçons de gymnastique 1-2H proposent de multiples formes de jeux et d’exercices aux enfants âgés de 4 à 6 ans. Ils se dépensent tout en s’amusant.',
                    contact:
                    {
                        name: '',
                        phone: ''
                    }
                },

                {
                    title: 'Gymnastique 3-4H',
                    day: 'Mercredi',
                    hour: '17h10 à 18h00',
                    image: '/images/Gym3-4H.jpg',
                    text: 'Les leçons de gymnastique destinées aux enfants de 3-4H proposent des activités ludiques pour se dépenser physiquement tout en s’amusant.',
                    contact:
                    {
                        name: '',
                        phone: ''
                    }
                },


                {
                    title: 'Gymnastique dames',
                    day: 'Mercredi',
                    hour: '20h00 à 21h30',
                    image: '/images/Dames site.jpg',
                    text: 'L’objectif des entraînements est le maintien de la condition physique, de la souplesse et de l’agilité. Des exercices variés sont proposés, provenant de disciplines comme le Pilate, le yoga, le stretching, la relaxation. Des exercices de condition physique, des jeux et des marches en plein air à la belle saison sont également pratiqués.',
                    contact:
                    {
                        name: 'Marie-Claire Lachat',
                        phone: '079 481 80 87'
                    }
                },

                {
                    title: 'Groupe dames senior',
                    day: 'Lundi',
                    hour: '19h30 à 21h00',
                    image: '/images/Dames senior site.jpg',
                    text: 'La gymnastique douce permet le renforcement musculaire, l’entretien de la coordination et le maintien de l’équilibre. Des jeux et des exercices variés sont proposés pour favoriser maintien de la mobilité tout en développant l’esprit d’équipe et d’entraide.',
                    contact:
                    {
                        name: 'Catherine Bessire',
                        phone: '032 435 55 47'
                    }
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
</style>
