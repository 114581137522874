import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);
app.use(router);

function showResultModal(inTitle, inMessage) {
    const modal = document.getElementById('main-modal');

    const title = modal.getElementsByClassName('modal-title')[0];
    const message = modal.getElementsByClassName('modal-message')[0];

    title.innerHTML = inTitle;
    message.innerHTML = inMessage;

    modal.classList.remove('hidden');
}

function validateFormInput(name, email, message) {
    const MSG_MAX_LENGTH = 1 << 15;
    const MSG_MIN_LENGTH = 1 << 3;

    const reason = (() => {
        if (name.length <= 1) {
            return "Veuillez entrer un nom valide.";
        }

        if (email.length <= 5) {
            return "Veuillez entrer une adresse email valide.";
        }

        if (message.length < MSG_MIN_LENGTH) {
            return "Veuillez entrer un message valide. La taille minimale du message est de " + MSG_MIN_LENGTH + " caractères.";
        }

        if (message.length > MSG_MAX_LENGTH) {
            return "Veuillez entrer un message de moins de " + MSG_MAX_LENGTH + " caractères.";
        }
    })();

    const bSuccess = !reason || reason.length == 0;

    if (!bSuccess) {
        showResultModal("Données de contact invalides.", reason);
    }

    return bSuccess;
}

app.config.globalProperties.$sendMessage = function (name, email, message) {
    if (!validateFormInput(name, email, message)) {
        return;
    }

    const targetUrl = "//femina-vicques.ch/actions/process.php";

    const postData = 'name=' + encodeURIComponent(name)
        + '&email=' + encodeURIComponent(email)
        + '&message=' + encodeURIComponent(message);

    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
        if (this.readyState != 4) return;

        let bSuccess = false;
        let reason = "";

        if (this.status == 200) {
            const data = JSON.parse(this.responseText);
            bSuccess = !!data.success;
            reason = data.reason ?? "inconnue";
        }
        else {
            reason = "invalid response: code " + this.status;
        }

        const title = bSuccess ? "Message envoyé" : "Échec de l'envoi";
        const message = bSuccess ?
            "Le message a été envoyé, nous vous contacterons sous peu." :
            ("L'envoi a échoué pour la raison suivante: \"" + reason + "\".");


        showResultModal(title, message);
    };

    xhr.open('POST', targetUrl, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.send(postData);
}

app.mount('#app');
