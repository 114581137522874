<template>
    <section>
        <div>
            <h2>Charte</h2>
            <p>
                La société Fémina Vicques propose des activités gymniques et sportives établies aussi bien dans les domaines 
                du sport populaire que du sport de compétition. Elle vise à promouvoir les valeurs de respect, d’engagement et 
                d’amitié.
            </p>

            <p>
                Avec une offre variée, nous proposons aux enfants, aux jeunes et aux personnes de tout âge un plaisir convivial 
                procuré par la pratique sportive. Le potentiel de chacun et de chaque équipe est respecté.
            </p>

            <p>
                Actuellement, notre programme comprend la gymnastique, la gym et danse et l’athlétisme. L’offre commune de ces 
                disciplines au sein de notre société crée une dynamique mutuelle et favorise l’échange, la diversité, l’intégration 
                ainsi que les expériences multiples et globales. 
            </p>

            <p>
                Nous œuvrons dans les domaines de l’organisation, des services et de la représentation des intérêts de nos 
                membres. Au-delà de la pratique sportive, nous avons à cœur de promouvoir les activités qui renforcent les 
                liens sociaux, au sein de la société comme avec la collectivité régionale. 
            </p>

            <p>
                Nous visons la qualité dans les domaines de la formation, de l’entraînement, de la sécurité et de l’intégration.
            </p>

            <p>
                Nous souhaitons la bienvenue à tous ceux qui s’identifient à nos objectifs et qui se sentent concernés par 
                notre offre.
            </p>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

@import "@/scss/colors.scss";

section {
    padding: 2.5rem 30%;
    margin: 5rem 0;
    & > div {
        font-size: 1.2rem;
        max-width: 800px;
        
        text-align: left;

        & > h2 {
            color: $femina-color-blue;
            font-weight: 100;
            font-size: 2.5rem;
            text-transform: uppercase;
        }

        & > p {
            text-align: justify;
        }
    }

    width: 100%;
    
    background: #fafafafa;
    border: 1px solid #f7f7f7;
}

@media (max-width: 1500px) {
    
    section {
        padding: 2.5rem 20%;
    }

}

@media (max-width: 1000px) {
    
    section {
        padding: 2.5rem 10%;
    }

}
</style>
