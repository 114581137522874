<template>
    <footer>
        &#169; Fémina Vicques {{ date }}.
    </footer>
</template>

<script>
export default {
    computed: {
        date: () => {
            return 1900 + new Date().getYear()
        }
    }
}
</script>

<style lang="scss" scoped>

footer {
    margin: 3rem 0;
    width: 100%;
    text-align: center;
}

</style>
