<template>
    <div class="wrapper">
        <div class="gallery-title">
            <h2>
                {{ event.title }}
            </h2>
            <h3 v-if="event.description && event.description.length > 0">
                {{ event.description }}
            </h3>
        </div>
        <div class="gallery-image" v-if="event.notice && event.notice.length > 0">
            <p>
                {{ event.notice }}


            </p>
            <p>

                <a href="https://lucie-photographie.com/">lucie-photographie.com</a>

            </p>
        </div>
        <div v-for="image in event.images" :key="image" class="gallery-image">
            <div>
                <img :src="image" />
                <div class="button-overlay">
                    <a :download="image.split('/').pop()" :href="image">Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

const GalleryData = require('@/data/gallery.json');

export default {
    name: "Gallery",

    data: () => {
        return {
            events: GalleryData
        }
    },

    computed: {
        event: function () {
            return this.events[this.$route.params.event];
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";

.wrapper {
    margin-top: 300px;

    &>div.gallery-title {
        margin-left: 20%;

        h2 {
            color: $femina-color-blue;
            font-weight: 100;
            font-size: 2.5rem;
            text-transform: uppercase;
            margin-bottom: 0.6rem;
        }

        h3 {
            margin-top: 0;
            -color: $femina-color-green;
            font-weight: 100;
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }
    }

    &>div:nth-child(2) {
        background-color: #fafafa;
    }

    &>div.gallery-image {
        padding: 2% 10%;
        text-align: center;

        position: relative;

        &>div {
            max-width: 70%;
            left: 15%;
            position: relative;

            &>img {
                max-width: 60%;
                border-radius: 4px;
            }

            &>div.button-overlay {
                position: absolute;
                width: 100%;
                bottom: 0;
                opacity: 0;

                &>a {
                    font-size: 1.5rem;
                    padding: 15px 35px;
                    background-color: rgba($color: black, $alpha: 0.3);
                    border: 1px solid #eee;
                    border-radius: 10px;
                    color: white;
                    cursor: pointer;
                    text-decoration: none;
                    transition: background-color 0.3s ease-in-out;

                    &:hover {
                        background-color: rgba($color: black, $alpha: 0.4);
                    }

                    &:active {
                        background-color: rgba($color: black, $alpha: 0.6);
                    }
                }


                transition: 0.3s ease-in-out;
            }

            &:hover>div.button-overlay {
                opacity: 1;
                bottom: 40px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .wrapper {
        margin-top: 150px;
    }
}

@media (max-width: 1060px) {
    .wrapper {
        margin-top: 0;

        &>div.gallery-title {
            margin-left: 5%;

            h2 {
                font-size: 2rem;
            }

            h3 {
                font-size: 1.1rem;
            }
        }

        &>div:nth-child(2) {
            background-color: #fafafa;
        }

        &>div.gallery-image {
            padding: 2% 5%;
            text-align: center;

            position: relative;

            &>div {
                max-width: 100%;
                left: 0;

                &>div.button-overlay {
                    bottom: 0;
                    opacity: 0;

                    &>a {
                        font-size: 16px;
                        padding: 10px 25px;
                    }
                }

                &:hover>div.button-overlay {
                    opacity: 1;
                    bottom: 30px;
                }
            }
        }
    }
}
</style>
