<template>
    <div class="root">
        <div class="what-is">
            <h2>La gym et danse</h2>
            <p>
                La gym et danse se pratique avec ou sans engins à main (ballon, cerceau, corde, massues et ruban), ou avec 
                des engins non conventionnels (parapluie, bâton, etc.). Les exercices se pratiquent individuellement, à deux, 
                en team (3 à 5 gymnastes) ou en groupe (à partir de 6 gymnastes), sur de la musique. C'est une discipline qui 
                demande concentration, précision, coordination, patience et persévérance. 
            </p>
        </div>
        <template v-for="offer in sportiveOffers" :key="offer.title">
            <SportiveOffer
                :title="offer.title"
                :day="offer.day"
                :hour="offer.hour"
                :text="offer.text"
                :image="offer.image"
                :age="offer.age"
                :contact="offer.contact"
            ></SportiveOffer>
        </template>
    </div>
</template>

<script>
import SportiveOffer from '@/components/SportiveOffer.vue'

export default {
    components:  { SportiveOffer },

    data: () => {
        return {
            sportiveOffers: [
                {
                    title: 'Groupe 5-6H',
                    age: 'De 8 à 10 ans',
                    day: 'Lundi',
                    hour: '17h45 à 19h15',
                    image: '/images/Gym-dansegroupe56H.jpg',
                    text: '',
                    contact: 
                    {
                        name: 'Léonie Maître',
                        phone: '079 954 99 49'
                    }
                },
                {
                    title: 'Groupe 7-8H',
                    age: 'De 10 à 12 ans',
                    day: 'Lundi',
                    hour: '16h45 à 18h15',
                    image: '/images/Gym-dansegroupe78H.jpg',
                    text: '',
                    contact: 
                    {
                        name: 'Léonie Maître',
                        phone: '079 954 99 49'
                    }
                },
                
                {
                    title: 'Groupe cracks',
                    age: 'De 12 à 15 ans',
                    day: 'Lundi',
                    hour: '19h15 à 20h45',
                    image: '/images/Gym-danse groupe cracks.jpg',
                    text: '',
                    contact: 
                    {
                        name: 'Lise Maître',
                        phone: '079 897 43 91'
                    }
                },
                
                {
                    title: 'Groupe actives',
                    age: 'Dès 16 ans',
                    day: 'Mercredi',
                    hour: '19h45 à 21h00',
                    image: '/images/Gym-danse groupe actives.jpg',
                    text: '',
                    contact: 
                    {
                        name: 'Malika Chappuis',
                        phone: '079 761 46 54'
                    }
                },
                
                {
                    title: 'Gym et danse individuelle et à deux',
                    day: 'Mercredi',
                    hour: '18h à 20h',
                    image: '/images/Gym-danse individuelle et à deux.jpg',
                    text: 'Ce groupe est destiné aux gymnastes qui souhaitent créer et présenter des chorégraphies individuellement ou à deux.',
                    contact: 
                    {
                        name: 'Léonie Maître',
                        phone: '079 954 99 49'
                    }
                },
                
                {
                    title: 'Groupe dames actives',
                    day: 'Mercredi',
                    hour: '19h30 à 21h',
                    image: '/images/Actives_dames_site.jpg',
                    text: 'L’objectif des entraînements est de danser sur des chorégraphies qui mêlent plusieurs styles de danse comme le moderne, le contemporain, etc.',
                    contact: 
                    {
                        name: 'Perrine Evard',
                        phone: '078 845 61 36'
                    }
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>

@import "@/scss/colors.scss";

.root {
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.what-is {
    text-align: justify;
    background: #fafafafa;
    border: 1px solid #f7f7f7;

    & > h2 {
        font-weight: 100;
        color: $femina-color-green;
    }

    max-width: 1100px;
    padding: 20px 40px;
    font-size: 1.2rem;
    margin: 4rem 0;
}

</style>
